import { API } from "aws-amplify";

const LocationsGet = async ({ organizationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { organizationId },
    };

    const { data } = await API.get("locations", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const LocationGet = async ({ locationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { locationId },
    };

    const { data } = await API.get("location", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const LocationCreate = async ({ createLocationData }) => {
  try {
    let reqData = {
      response: true,
      body: createLocationData,
    };

    const { data } = await API.post("location", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const LocationUpdate = async ({ updateLocationData }) => {
  try {
    let reqData = {
      response: true,
      body: updateLocationData,
    };

    const { data } = await API.put("location", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const Methods = {
  LocationGet,
  LocationsGet,
  LocationCreate,
  LocationUpdate,
};

export default Methods;
