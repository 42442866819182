import { API } from "aws-amplify";

const AlarmsGet = async ({ organizationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { organizationId },
    };

    const { data } = await API.get("alarms", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const AlarmsGetTankAlarms = async ({ tankId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { tankId },
    };

    const { data } = await API.get("alarms", "tank", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const AlarmGet = async ({ alarmId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { alarmId },
    };

    const { data } = await API.get("alarm", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const AlarmGetActivityLog = async ({ alarmId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { alarmId },
    };

    const { data } = await API.get("alarm", "activity", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const AlarmPostComment = async ({ alarmId, comment }) => {
  try {
    let reqData = {
      response: true,
      body: { alarmId, comment },
    };

    const { data } = await API.post("alarm", "comment", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const AlarmPostCreateTestAlarm = async ({ tankId }) => {
  try {
    let reqData = {
      response: true,
      body: { tankId },
    };

    const { data } = await API.post("alarm", "test", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const AlarmUpdateResolutionStatus = async ({ alarmId, status }) => {
  try {
    let reqData = {
      response: true,
      body: { alarmId, status },
    };

    const { data } = await API.put("alarm", "resolution-status", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const Methods = {
  AlarmsGet,
  AlarmGet,
  AlarmsGetTankAlarms,
  AlarmGetActivityLog,
  AlarmPostComment,
  AlarmUpdateResolutionStatus,
  AlarmPostCreateTestAlarm,
};

export default Methods;
