import { API } from "aws-amplify";

const OnCallGetOrganizationOncall = async ({ organizationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { organizationId },
    };

    const { data } = await API.get("oncall", "organization", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const OnCallGetLocationOnCall = async ({ locationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { locationId },
    };

    const { data } = await API.get("oncall", "location", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const OnCallUpdateLocationOnCall = async ({ updateOnCallData }) => {
  try {
    let reqData = {
      response: true,
      body: updateOnCallData,
    };

    const { data } = await API.put("oncall", "location", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const Methods = {
  OnCallUpdateLocationOnCall,
  OnCallGetLocationOnCall,
  OnCallGetOrganizationOncall,
};

export default Methods;
