import { API } from "aws-amplify";

const MetricsDashboardGet = async ({ resource, resourceId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { resource, resourceId },
    };

    const { data } = await API.get("metrics", "dashboard", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const Methods = {
  MetricsDashboardGet,
};

export default Methods;
