import { API } from "aws-amplify";

const AlarmTrendsByLocationGet = async ({ organizationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { organizationId },
    };

    const { data } = await API.get(
      "charts",
      "alarm-trends-by-location",
      reqData
    );
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const TankWeightTrendsForLocationGet = async ({ locationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { locationId },
    };

    const { data } = await API.get(
      "charts",
      "tank-weight-trends-for-location",
      reqData
    );
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const TankWeightTrendGet = async ({ tankId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { tankId },
    };

    const { data } = await API.get("charts", "tank-weight-trend", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const Methods = {
  AlarmTrendsByLocationGet,
  TankWeightTrendsForLocationGet,
  TankWeightTrendGet,
};

export default Methods;
