import { API } from "aws-amplify";

const ActivityGet = async ({ resource, resourceId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { resource, resourceId },
    };

    const { data } = await API.get("activity", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const ActivityGetAll = async ({ organizationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { organizationId },
    };

    const { data } = await API.get("activity", "all", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const Methods = {
  ActivityGet,
  ActivityGetAll,
};

export default Methods;
