import {
  Authenticator,
  View,
  Image,
  Heading,
  useTheme,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import Logo from "../../theme/images/Boreas_Logo_Color.png";
import colors from "../../theme/colors";
import UnAuthFooter from "../Navigation/unauth-footer";
import styled from "styled-components";

const AuthContainer = styled.div`
  min-height: calc(100vh - 90px);
  margin-bottom: 20px;
  @media (max-width: 900px) {
    min-height: calc(100vh - 210px);
  }
`;

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View style={{ display: "flex", justifyContent: "center" }}>
        <Image
          style={{ maxWidth: "380px" }}
          alt="Boreas Monitoring Logo"
          src={Logo}
        />
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Please Sign In
        </Heading>
      );
    },
  },
};

const formFields = {
  signIn: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
  resetPassword: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
};

I18n.putVocabulariesForLanguage("en", {
  "Incorrect username or password.": "Incorrect email or password.",
  "Username/client id combination not found.": "Incorrect email or password.",
  "User does not exist.": "Incorrect email or password.",
});

const customCss = `
[data-amplify-authenticator] {
    --amplify-components-heading-color: ${colors.blueDark};
    --amplify-components-button-active-background-color: red;
    --amplify-components-button-primary-background-color: ${colors.blue};
    --amplify-components-button-primary-hover-background-color: ${colors.blueDark};
  }
  [data-amplify-container] {
    padding: 10px 0;
  }
`;

export default function Login({ children }) {
  return (
    <>
      <AuthContainer>
        <style>{customCss}</style>
        <Authenticator
          hideSignUp={true}
          formFields={formFields}
          components={components}
        >
          {children}
        </Authenticator>
      </AuthContainer>
      <UnAuthFooter />
    </>
  );
}
