import styled from "styled-components";
import { Button, Skeleton, Card } from "@mui/material";
import colors from "../../theme/colors";

const NoPrintWrapper = styled.div`
  @media print {
    display: none !important;
  }
`;

const WidgetContainer = styled.div`
  padding: 20px;
`;

const CardWrapper = styled(Card)`
  margin: 0 15px 20px;
  @media (max-width: 1080px) {
    margin: 0 0px 20px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
`;

const WidgetHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  overflow-wrap: break-word;
`;

const WidgetSubHeader = styled.div`
  overflow-wrap: break-word;
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: ${colors.gray};
`;

const DetailsWrapper = styled.div`
  font-size: 12px;
`;

const DetailItemContainer = styled.div``;

const DetailsWidgetComponent = ({
  subtitle = "",
  title = "",
  address = "",
  phone = "",
  loading = true,
  showEdit = false,
  details = [],
  onEdit = () => {},
}) => {
  if (loading) {
    return (
      <CardWrapper>
        <WidgetContainer>
          <HeaderContainer>
            <div>
              <WidgetSubHeader>
                <Skeleton width={100} />
              </WidgetSubHeader>
              <WidgetHeader>
                <Skeleton width={100} />
              </WidgetHeader>
            </div>
            <Skeleton width={50} height={40} />
          </HeaderContainer>
          <DetailsWrapper>
            <DetailItemContainer>
              <Skeleton />
            </DetailItemContainer>
            <DetailItemContainer>
              <Skeleton />
            </DetailItemContainer>
          </DetailsWrapper>
        </WidgetContainer>
      </CardWrapper>
    );
  } else {
    return (
      <CardWrapper>
        <WidgetContainer>
          <HeaderContainer>
            <WidgetSubHeader>
              {subtitle}
              {showEdit && (
                <NoPrintWrapper>
                  <Button
                    sx={{
                      boxShadow: 2,
                      fontSize: "10px",
                      padding: "4px 12px",
                      minWidth: "auto",
                    }}
                    size="small"
                    color="black"
                    onClick={onEdit}
                  >
                    Edit
                  </Button>
                </NoPrintWrapper>
              )}
            </WidgetSubHeader>
            <WidgetHeader>{title}</WidgetHeader>
          </HeaderContainer>
          <DetailsWrapper>
            {details.map((detail, i) => {
              return (
                <DetailItemContainer key={`detail-${i}`}>
                  {detail}
                </DetailItemContainer>
              );
            })}
          </DetailsWrapper>
        </WidgetContainer>
      </CardWrapper>
    );
  }
};

export default DetailsWidgetComponent;
