import { API } from "aws-amplify";

const TanksGet = async ({ organizationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { organizationId },
    };

    const { data } = await API.get("tanks", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const TankGet = async ({ tankId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { tankId },
    };

    const { data } = await API.get("tank", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const TankGetNotes = async ({ tankId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { tankId },
    };

    const { data } = await API.get("tank", "notes", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const TankCreateNote = async ({ tankId, note }) => {
  try {
    let reqData = {
      response: true,
      body: { tankId, note },
    };

    const { data } = await API.post("tank", "notes", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const TankGetAttributes = async () => {
  try {
    let reqData = {
      response: true,
    };

    const { data } = await API.get("tank", "attributes", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const TankGetFillEvents = async ({ tankId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { tankId },
    };

    const { data } = await API.get("tank", "fill-events", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const TankCreate = async ({ createTankData }) => {
  try {
    let reqData = {
      response: true,
      body: createTankData,
    };

    const { data } = await API.post("tank", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const TankUpdate = async ({ editTankData }) => {
  try {
    let reqData = {
      response: true,
      body: editTankData,
    };

    const { data } = await API.put("tank", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const TankDelete = async ({ deleteTankId, reactivate }) => {
  try {
    let reqData = {
      response: true,
      body: { deleteTankId },
    };

    if (reactivate) {
      reqData.body.reactivate = true;
    }

    const { data } = await API.del("tank", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const Methods = {
  TanksGet,
  TankGet,
  TankCreate,
  TankUpdate,
  TankGetAttributes,
  TankDelete,
  TankGetNotes,
  TankCreateNote,
  TankGetFillEvents,
};

export default Methods;
