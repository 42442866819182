// Boiler Plate Imports
import { useEffect, useState } from "react";
import colors from "../../theme/colors";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import { redirect, useNavigate, useParams } from "react-router-dom";

// Conatiner Component Imports
import { Button, Card } from "@mui/material";
import Table from "../../components/Table";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import TableHeader from "../../components/Page/tableHeader";
import { NoTableDataPlaceholder } from "../../components/NoDataPlaceholder";

// Page Component Imports
import MetricBoxes from "../../components/Page/metricBoxes";
import TableSubHeader from "../../components/Page/tableSubHeader";

import DeviceConnectivityTestModal from "./deviceConnectivityTestModal";
import { updateDeviceConnectivityTestModal } from "../../redux-store/uxSlice";

const PaddingWrapper = styled.div`
  padding: 0 15px 10px;
  @media (max-width: 1080px) {
    padding: 0 0px 10px;
  }
`;

const AdminDeviceDashboardContainer = () => {
  const navigate = useNavigate();
  const { deviceId } = useParams();

  const [device, setDevice] = useState({});
  const [loadingDevice, setLoadingDevice] = useState(true);

  const dispatch = useDispatch();

  const deviceTableColumns = [
    {
      title: "Name",
      propertyName: "name",
      sortProperty: "name",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/admin/devices/${row.id}`;
      },
      sortDefault: "desc",
    },
    {
      title: "Particle Device ID",
      propertyName: "particleDeviceId",
      sortProperty: "particleDeviceId",
      sortPropertyType: "string",
    },
    {
      title: "Device Status",
      propertyName: "status",
      sortProperty: "status",
      sortPropertyType: "string",
    },
    {
      title: "Firmware Status",
      propertyName: "firmwareStatus",
      sortProperty: "firmwareStatus",
      sortPropertyType: "string",
    },
    {
      title: "Calibration Status",
      propertyName: "calibrationStatus",
      sortProperty: "calibrationStatus",
      sortPropertyType: "string",
    },
    {
      title: "Connectivity Test Status",
      propertyName: "connectivityTestStatus",
      sortProperty: "connectivityTestStatus",
      sortPropertyType: "string",
    },

    {
      title: "Date Created",
      propertyName: "dateCreatedString",
      sortProperty: "created",
      sortPropertyType: "number",
    },
  ];

  useEffect(() => {
    dataLayer.GetDevice();
    dispatch(
      setBreadCrumbs([
        {
          title: "Admin Devices",
          link: "/admin/devices",
        },
        {
          title: `...`,
          link: `/admin/devices/`,
        },
      ])
    );
  }, []);

  useEffect(() => {
    if (device.name) {
      dispatch(
        setBreadCrumbs([
          {
            title: "Admin Devices",
            link: "/admin/devices",
          },
          {
            title: `${device.name}`,
            link: `/admin/devices/${deviceId}`,
          },
        ])
      );
    }
  }, [device]);

  const dataLayer = {
    GetDevice: async () => {
      const device = await API.Device.DeviceGet({ deviceId });
      setDevice(device);

      const metrics = {};

      setLoadingDevice(false);
    },
    DeleteDevice: async () => {
      const _res = await API.Device.DeviceDelete({ deviceId });
    },
  };

  const handlers = {
    deleteDevice: async () => {
      await dataLayer.DeleteDevice();
      navigate("/admin/devices");
    },
    calibrateDevice: async () => {
      navigate("calibrate");
    },
    startConnectivityTest: async () => {
      dispatch(
        updateDeviceConnectivityTestModal({
          show: true,
          device,
        })
      );
    },
  };

  return (
    <>
      <BreadCrumbs />
      <PaddingWrapper>
        <Card>
          <h1>Device Dashboard</h1>
          {/* <Button
            variant="outlined"
            color="error"
            onClick={handlers.deleteDevice}
          >
            Deactivate Device
          </Button> */}

          {!loadingDevice && (
            <>
              <Button variant="outlined" onClick={handlers.calibrateDevice}>
                Calibrate Device
              </Button>

              <Button
                variant="outlined"
                onClick={handlers.startConnectivityTest}
              >
                Start Connectivity Test
              </Button>
            </>
          )}
        </Card>
      </PaddingWrapper>
      <DeviceConnectivityTestModal />
    </>
  );
};

export default AdminDeviceDashboardContainer;
