import { Amplify, Auth } from "aws-amplify";

let baseUrl = "";
let cognito = {};

console.log("REACT_APP_ENV", process.env.REACT_APP_ENV);

console.log("REACT_APP_API_ENDPOINT", process.env.REACT_APP_API_ENDPOINT);

console.log("REACT_APP_COGNITO_REGION", process.env.REACT_APP_COGNITO_REGION);

console.log(
  "REACT_APP_COGNITO_USER_POOL_ID",
  process.env.REACT_APP_COGNITO_USER_POOL_ID
);

console.log(
  "REACT_APP_COGNITO_APP_CLIENT_ID",
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID
);

baseUrl = process.env.REACT_APP_API_ENDPOINT;
cognito = {
  REGION: process.env.REACT_APP_COGNITO_REGION,
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
};

const config = {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "us-west-2",
    user: `${baseUrl}/user/`,
    userAdmins: `${baseUrl}/user/admins/`,
    userOrganizationUsers: `${baseUrl}/user/organization/`,
    organization: `${baseUrl}/organization/`,
    organizations: `${baseUrl}/organizations/`,
    location: `${baseUrl}/location/`,
    locations: `${baseUrl}/locations/`,
    tank: `${baseUrl}/tank/`,
    tanks: `${baseUrl}/tanks/`,
    alarms: `${baseUrl}/alarms/`,
    alarm: `${baseUrl}/alarm/`,
    activity: `${baseUrl}/activity/`,
    oncall: `${baseUrl}/oncall/`,
    metrics: `${baseUrl}/metrics/`,
    charts: `${baseUrl}/charts/`,
    device: `${baseUrl}/device/`,
  },
  cognito,
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "user",
        endpoint: config.apiGateway.user,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "user/admins",
        endpoint: config.apiGateway.userAdmins,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "user/organization",
        endpoint: config.apiGateway.userOrganizationUsers,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "organization",
        endpoint: config.apiGateway.organization,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "organizations",
        endpoint: config.apiGateway.organizations,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "location",
        endpoint: config.apiGateway.location,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "locations",
        endpoint: config.apiGateway.locations,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "tank",
        endpoint: config.apiGateway.tank,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "tanks",
        endpoint: config.apiGateway.tanks,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "alarm",
        endpoint: config.apiGateway.alarm,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "alarms",
        endpoint: config.apiGateway.alarms,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "activity",
        endpoint: config.apiGateway.activity,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "oncall",
        endpoint: config.apiGateway.oncall,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "metrics",
        endpoint: config.apiGateway.metrics,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "charts",
        endpoint: config.apiGateway.charts,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "device",
        endpoint: config.apiGateway.device,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
    ],
  },
});
