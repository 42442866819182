import { API } from "aws-amplify";

const OrganizationGet = async ({ organizationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { organizationId },
    };

    const { data } = await API.get("organization", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const OrganizationsGetAll = async () => {
  try {
    let reqData = {
      response: true,
    };

    const { data } = await API.get("organizations", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const OrganizationCreate = async ({ createOrganizationData }) => {
  try {
    let reqData = {
      response: true,
      body: createOrganizationData,
    };

    const { data } = await API.post("organization", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const OrganizationUpdate = async ({ updateOrganizationData }) => {
  try {
    let reqData = {
      response: true,
      body: updateOrganizationData,
    };

    const { data } = await API.put("organization", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const OrganizationDelete = async ({ deleteOrganizationId, reactivate }) => {
  try {
    let reqData = {
      response: true,
      body: { deleteOrganizationId },
    };

    if (reactivate) {
      reqData.body.reactivate = true;
    }

    const { data } = await API.del("organization", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const Methods = {
  OrganizationGet,
  OrganizationsGetAll,
  OrganizationCreate,
  OrganizationUpdate,
  OrganizationDelete,
};

export default Methods;
