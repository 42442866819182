// Boiler Plate Imports
import { useEffect, useState } from "react";
import colors from "../../theme/colors";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import { useNavigate } from "react-router-dom";

// Conatiner Component Imports
import { Button, Card } from "@mui/material";
import Table from "../../components/Table";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import TableHeader from "../../components/Page/tableHeader";
import { NoTableDataPlaceholder } from "../../components/NoDataPlaceholder";

// Page Component Imports
import MetricBoxes from "../../components/Page/metricBoxes";
import TableSubHeader from "../../components/Page/tableSubHeader";
import { OpenLinkNewWindowIcon } from "../../theme/icons";
import { formatDateTimeStamp } from "../../utils/date";

import DeviceConnectivityTestModal from "./deviceConnectivityTestModal";
import { updateDeviceConnectivityTestModal } from "../../redux-store/uxSlice";

const LowerCaseButton = styled(Button)`
  text-transform: none !important;
`;

const PaddingWrapper = styled.div`
  padding: 0 15px 10px;
  @media (max-width: 1080px) {
    padding: 0 0px 10px;
  }
`;

const StatusTag = styled.div`
  border-radius: 15px;
  text-align: center;
  padding: 2px;
  width: 87px;
  font-weight: bold;
  background: ${colors.grayLight};
  color: ${colors.grayDark};
  border: 1px solid ${colors.gray};
  &.red {
    background: ${colors.redLightError};
    color: ${colors.redError};
    border: 1px solid ${colors.redError};
  }
  &.yellow {
    background: ${colors.yellowLight};
    color: ${colors.yellow};
    border: 1px solid ${colors.yellow};
  }
  &.green {
    background: ${colors.greenLightSuccess};
    color: ${colors.greenSuccess};
    border: 1px solid ${colors.greenSuccess};
  }
`;

const AdminDevicesContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deviceTableRows, setDeviceTableRows] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState(true);
  const [deviceMetrics, setDeviceMetrics] = useState([]);

  const deviceTableColumns = [
    {
      title: "Name",
      propertyName: "name",
      sortProperty: "name",
      sortPropertyType: "string",
      // navigate: (row) => {
      //   return `/admin/devices/${row.id}`;
      // },
      render: (row) => {
        return (
          <LowerCaseButton
            // target="_blank"
            href={`/admin/devices/${row.id}`}
            rel="noreferrer"
          >
            {row.name}
            {/* <OpenLinkNewWindowIcon
              sx={{ fontSize: "16px", marginLeft: "3px" }}
            /> */}
          </LowerCaseButton>
        );
      },
    },
    {
      title: "Particle Device ID",
      propertyName: "particleDeviceId",
      sortProperty: "particleDeviceId",
      sortPropertyType: "string",
      render: (row) => {
        return (
          <LowerCaseButton
            target="_blank"
            href={`https://console.particle.io/boreas-monitoring-bsom-${row.product}/devices/${row.particleDeviceId}`}
            rel="noreferrer"
          >
            {row.particleDeviceId}
            <OpenLinkNewWindowIcon
              sx={{ fontSize: "16px", marginLeft: "3px" }}
            />
          </LowerCaseButton>
        );
      },
    },
    {
      title: "Firmware Status",
      propertyName: "latestFirmwareInstallEvent",
      sortProperty: "latestFirmwareInstallEvent",
      sortPropertyType: "string",
      render: (row) => {
        let statusString = "";
        let className = "";
        switch (row.latestFirmwareInstallEvent) {
          case "triggered":
            statusString = "Queued";
            className = "yellow";
            break;
          case "started":
            statusString = "Started";
            className = "yellow";
            break;
          case "success":
            statusString = "Restarting";
            className = "yellow";
            break;
          case "failed":
            statusString = "Failed";
            className = "red";
            break;
          case "running":
            statusString = "Running";
            className = "green";
            break;
          default:
            statusString = "Running";
            className = "green";
        }
        return <StatusTag className={className}>{statusString}</StatusTag>;
      },
    },
    {
      title: "Firmware Version",
      propertyName: "fwVersion",
      sortProperty: "fwVersion",
      sortPropertyType: "number",
      render: (row) => {
        return <>v{row.fwVersion}</>;
      },
    },
    {
      title: "Quality Certificate",
      propertyName: "qcIsValid",
      sortProperty: "qcIsValid",
      sortPropertyType: "boolean",
      render: (row) => {
        if (row?.qcIsValid) {
          return (
            <Button
              target="_blank"
              href={`devices/${row.id}/qc`}
              rel="noreferrer"
            >
              View QC
              <OpenLinkNewWindowIcon
                sx={{ fontSize: "16px", marginLeft: "3px" }}
              />
            </Button>
          );
        } else {
          return (
            <Button
              target="_blank"
              disabled
              href={`devices/${row.id}/qc`}
              rel="noreferrer"
            >
              View QC
              <OpenLinkNewWindowIcon
                sx={{ fontSize: "16px", marginLeft: "3px" }}
              />
            </Button>
          );
        }
      },
    },
    {
      title: "Calibration",
      propertyName: "calibrationStatus",
      sortProperty: "calibrationStatus",
      sortPropertyType: "string",
      render: (row) => {
        switch (row?.calibrationStatus) {
          case "no-cal":
            return (
              <Button
                target="_blank"
                href={`devices/${row.id}/calibrate`}
                rel="noreferrer"
              >
                Calibrate
                <OpenLinkNewWindowIcon
                  sx={{ fontSize: "16px", marginLeft: "3px" }}
                />
              </Button>
            );
          default:
            return row?.calibrationStatus;
        }
      },
    },
    {
      title: "Connectivity Test",
      propertyName: "connectivityTestStatus",
      sortProperty: "connectivityTestStatus",
      sortPropertyType: "string",
      render: (row) => {
        switch (row?.connectivityTestStatus) {
          case "no-test":
            return (
              <Button
                onClick={() =>
                  handlers.openConnectivityTestModalClick({
                    device: row,
                  })
                }
              >
                Start Test
              </Button>
            );
          default:
            return row?.connectivityTestStatus;
        }
      },
    },
    {
      title: "Date Created",
      propertyName: "dateCreatedString",
      sortProperty: "created",
      sortPropertyType: "number",
      sortDefault: "desc",
    },
  ];

  //Polling Setup
  const [dataInterval, setDataInterval] = useState();
  let refreshIntervalSeconds = 10;
  const [pollingStarted, setPollingStarted] = useState(false);
  const [nextRefreshInSeconds, setNextRefreshInSeconds] = useState(
    refreshIntervalSeconds
  );

  useEffect(() => {
    dataLayer.GetAllDevices();
    dispatch(
      setBreadCrumbs([
        {
          title: "Admin Devices",
          link: "/admin/devices",
        },
      ])
    );
    setDeviceMetrics([
      {
        title: "TOTAL DEVICES",
        value: "-",
      },
    ]);
  }, []);

  useEffect(() => {
    if (!pollingStarted) {
      PollData();
      setPollingStarted(true);
    }
    //Clear time on unmount
    return () => {
      setPollingStarted(false);
      clearInterval(dataInterval);
    };
  }, []);

  useEffect(() => {
    if (nextRefreshInSeconds === 0) {
      FetchData();
      setNextRefreshInSeconds(refreshIntervalSeconds);
    }
  }, [nextRefreshInSeconds]);

  const PollData = async () => {
    clearInterval(dataInterval);
    const d = setInterval(() => {
      setNextRefreshInSeconds((s) => s - 1);
    }, 1000);
    setDataInterval(d);
  };

  const FetchData = async () => {
    dataLayer.GetAllDevices();
  };
  //END Polling

  const dataLayer = {
    GetAllDevices: async () => {
      const devices = await API.Device.DeviceGetAll();

      setDeviceMetrics([
        {
          title: "TOTAL DEVICES",
          value: devices.length,
        },
      ]);

      const devicesRows = devices.map((device) => {
        let calibrationStatus = "no-cal";
        if (
          device.calibrationRecords.length &&
          device.calibrationRecords[0].finishDate
        ) {
          if (device.calibrationRecords[0]?.passed) {
            calibrationStatus = `Completed - ${formatDateTimeStamp(
              device.calibrationRecords[0].finishDate
            )}`;
          } else if (device.calibrationRecords[0]?.failed) {
            calibrationStatus = `Failed - ${formatDateTimeStamp(
              device.calibrationRecords[0].finishDate
            )}`;
          }
        } else if (device.calibrationRecords.length) {
          calibrationStatus = `Started - ${formatDateTimeStamp(
            device.calibrationRecords[0].startDate
          )}`;
        }

        let connectivityTestStatus = "no-test";
        if (
          device.connectivityTestRecords.length &&
          device.connectivityTestRecords[0].finishDate
        ) {
          if (device.connectivityTestRecords[0]?.passed === true) {
            connectivityTestStatus = `Completed - ${formatDateTimeStamp(
              device.connectivityTestRecords[0].finishDate
            )}`;
          } else if (device.connectivityTestRecords[0]?.passed === false) {
            connectivityTestStatus = `Failed - ${formatDateTimeStamp(
              device.connectivityTestRecords[0].finishDate
            )}`;
          }
        } else if (device.connectivityTestRecords.length) {
          connectivityTestStatus = `Started - ${formatDateTimeStamp(
            device.connectivityTestRecords[0].startDate
          )}`;
        }

        return {
          ...device,
          connectivityTestStatus,
          calibrationStatus,
          latestFirmwareInstallEvent:
            device.latestFirmwareInstallEvent || "running",
          fwVersion: device.particleDevice?.firmware_version,
          dateCreatedString: new Date(device.created).toLocaleString(),
        };
      });
      devicesRows.sort((a, b) => {
        return b.name - a.name;
      });
      setDeviceTableRows(devicesRows);
      setLoadingDevices(false);
    },
  };

  const handlers = {
    addDeviceClick: () => {
      navigate("add");
    },
    openConnectivityTestModalClick: async ({ device }) => {
      dispatch(
        updateDeviceConnectivityTestModal({
          show: true,
          device,
        })
      );
    },
  };

  return (
    <>
      <BreadCrumbs />
      <MetricBoxes title={""} metrics={deviceMetrics} />
      <PaddingWrapper>
        <Card>
          <TableHeader>
            <div>
              <h2>Devices</h2>
              <TableSubHeader>
                View all devices in the Boreas system below
              </TableSubHeader>
            </div>

            <Button
              onClick={handlers.addDeviceClick}
              variant="contained"
              color="primary"
              size="large"
            >
              ADD DEVICES
            </Button>
          </TableHeader>
          <Table
            columns={deviceTableColumns}
            rows={deviceTableRows}
            loading={loadingDevices}
            searchEnabled={true}
            placeholder={
              <NoTableDataPlaceholder
                header="There are no devices in the system yet"
                body="To get started add a device"
                buttonText="Add Device"
                buttonPath="add"
              />
            }
          />
        </Card>
      </PaddingWrapper>
      <DeviceConnectivityTestModal />
    </>
  );
};

export default AdminDevicesContainer;
