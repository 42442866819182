import styled from "styled-components";
import colors from "../../theme/colors";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const NoChartDataPlaceholder = ({ header, body, buttonText, buttonPath }) => {
  const PlaceholderContainer = styled.div`
    border-top: 1px solid ${colors.grayLight};
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 60px;
    font-size: 18px;
    color: ${colors.black};
  `;
  const PlaceholderHeader = styled.div``;
  const PlaceholderBody = styled.div`
    color: ${colors.blue};
  `;
  return (
    <PlaceholderContainer>
      <PlaceholderHeader>{header}</PlaceholderHeader>
      <PlaceholderBody>{body}</PlaceholderBody>
      <Button component={Link} variant="contained" to={buttonPath}>
        {buttonText}
      </Button>
    </PlaceholderContainer>
  );
};

const NoTableDataPlaceholder = ({ header, body, buttonText, buttonPath }) => {
  const PlaceholderContainer = styled.div`
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 60px;
    font-size: 18px;
    color: ${colors.black};
  `;
  const PlaceholderHeader = styled.div``;
  const PlaceholderBody = styled.div`
    color: ${colors.blue};
  `;
  return (
    <PlaceholderContainer>
      <PlaceholderHeader>{header}</PlaceholderHeader>
      <PlaceholderBody>{body}</PlaceholderBody>
      <Button component={Link} variant="contained" to={buttonPath}>
        {buttonText}
      </Button>
    </PlaceholderContainer>
  );
};

export { NoChartDataPlaceholder, NoTableDataPlaceholder };
